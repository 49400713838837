import {useEffect, useState} from "react";

const Respuesta = () => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const recibirDatos = async () => {
      try {
        const response = await fetch('/paginaRespuesta', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data)
          setData(data);
        } else {
          console.error('Error al recibir los datos');
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };

    recibirDatos();
  }, []);

  if (!data) {
    return <div>Cargando datos...</div>;
  }

  return (
    <div>
      <h1>Datos Recibidos</h1>
      <p>Nombre: {data.transactionToken}</p>
      <p>Email: {data.customerEmail}</p>
      <p>Channel: {data.channel}</p>
    </div>
  );
}

export default Respuesta
