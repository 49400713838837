/* global VisanetCheckout */
import React, {useEffect, useState} from 'react';
import {Apis} from "../util/data";

const PagoNiubiz = ({purchaseNumber, callbackUrl, amount, sessionToken }) => {

  // const [tokenObtenido,setTokenObtenido] = useState('')
  // const [email,setEmail] = useState('')
  const [isNiubizLoaded, setIsNiubizLoaded] = useState(false);

  // useEffect(()=>{
  //   if(tokenObtenido.length > 0){
  //     window.location.href = `${callbackUrl}?data=${encodeURIComponent(tokenObtenido)}&amount=${
  //       encodeURIComponent(amount)}&email=${email}`;
  //   }
  // },[tokenObtenido])

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static-content-qas.vnforapps.com/v2/js/checkout.js';
    script.async = true;

    script.onload = () => {
      if (typeof VisanetCheckout !== 'undefined') {
        setIsNiubizLoaded(true);
      } else {
        console.error('Niubiz no está definido');
      }
    };

    document.body.appendChild(script);
  }, []);

  useEffect(()=>{
    if(isNiubizLoaded && purchaseNumber.length > 0 && amount !== 0.0 && callbackUrl.length > 0){
      const urlEncoded = encodeURIComponent(callbackUrl);
      console.log("effect:")
      console.log(purchaseNumber)
      VisanetCheckout.configure({
        sessiontoken:sessionToken,
        channel:'web',
        merchantid:'456879852',
        purchasenumber:purchaseNumber,
        amount:amount.toFixed(2),
        expirationminutes:'10',
        timeouturl:'about:blank',
        merchantlogo:'img/comercio.png',
        formbuttoncolor:'#000000',
        action: Apis.PROD + '/pago/autorice?callback=' + urlEncoded + '&purchaseNumber=' + purchaseNumber +
          '&amount=' + amount.toFixed(2),
        complete: function(params) {
          console.log("regresa");
          console.log(JSON.stringify(params));
        }
      });
      VisanetCheckout.open();
    }
  },[isNiubizLoaded,purchaseNumber,amount,callbackUrl])

  return (
    <div/>
  )
};

export default PagoNiubiz;
