import {Route, Routes} from "react-router";
import './App.css';
import PagosPage from "./pages/PagosPage";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActionContextWrapper from "./context/ActionContextWrapper";
import Respuesta from "./pages/Respuesta";

toast.configure()

function App() {
  return (
    <ActionContextWrapper>
      <Routes>
        <Route path="/paginaRespuesta" element={<Respuesta/>}/>
        <Route path="/" element={<PagosPage/>}/>
      </Routes>
    </ActionContextWrapper>
  );
}

export default App;
