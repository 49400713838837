import PageContainerFull from "../components/layout/PageContainerFull";
import {useEffect, useState} from "react";
import PagoNiubiz from "../components/PagoNiubiz";
import axios from "axios";
import {Apis} from "../util/data";

const PagosPage = () => {

  const [callbackUrl, setCallbackUrl] = useState('');
  const [amount, setAmount] = useState(0.0);
  const [sessionToken, setSessionToken] = useState('');
  const [usuarioId, setUsuarioId] = useState(0);
  const [purchaseNumber,setPurchaseNumber] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const callback = urlParams.get('callback');
    const amount = urlParams.get('amount');
    const token = urlParams.get('sessionToken');
    const usuarioId = urlParams.get('usuarioId');
    if (callback) {
      setCallbackUrl(callback);
    }
    if (amount) {
      setAmount(parseFloat(amount));
    }
    if(token){
      setSessionToken(token);
    }
    if(usuarioId){
      setUsuarioId(parseInt(usuarioId));
    }
  }, []);

  const getPurchaseNumber = async () =>{
    try {
      const {data} = await axios.get(Apis.PROD + "/pedidos/siguiente")
      if (data) {
        setPurchaseNumber(usuarioId.toString() + data);
      }
    }
    catch (error){
      console.log(error)
    }
  }

  useEffect(()=>{
    if(usuarioId !== 0) {
      getPurchaseNumber().catch()
    }
  },[usuarioId])

  return(
      <PageContainerFull style={{padding: 0}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center',height: '100vh'}}>
          <PagoNiubiz purchaseNumber={purchaseNumber} callbackUrl={callbackUrl} amount={amount} sessionToken={sessionToken} usuarioId={usuarioId}/>
        </div>
      </PageContainerFull>
  )
}

export default PagosPage
